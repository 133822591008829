
.hori-timeline .events {
    display: flex;
    flex-wrap: wrap;

    border-top: 3px solid #e9ecef;
}
.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}
.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 30px;
}
.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: -28px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}
.active-event-date{

}
@media (min-width: 1140px) {
    .hori-timeline{
        max-height:200px;
        overflow:auto ;
        padding-top: 10px;
    
    }
    .hori-timeline .events {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100vw;
        border-top: 3px solid #e9ecef;
    }
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 250px;
        padding-top: 65px;
    }
    .hori-timeline .events .event-list .event-date {
        top: 12px;
    }
}
.bg-soft-primary {
    background-color: rgba(64,144,203,.3)!important;
}
.bg-soft-success {
    background-color: rgba(71,189,154,.3)!important;
}
.bg-soft-danger {
    background-color: rgba(231,76,94,.3)!important;
}
.bg-soft-warning {
    background-color: rgba(249,213,112,.3)!important;
}
.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
    box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
}



